import React from "react";

import Banner from "./Banner";
import NFTcollection from "./NFTcollection";
import About from "./About";
import Benefits from "./Benefits";
import FAQ from "./FAQ";
import Footer from "components/Footer/Footer";

const Home = () => {
    return (
        <>
            <div className="container px-0">
                <Banner />
                <NFTcollection />
                <Benefits />
                <About />
                <FAQ />
                <Footer />
            </div>
        </>
    )
}

export default Home