import {
    Container,
    Row,
    Col,
    Table
} from "reactstrap";
import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"

const FAQ = () => {

    const [active, setIsActive] = useState(1)

    const toggle = (i) => {
        if (active === i) {
            return setIsActive(1)
        }
        setIsActive(i)
    }



    const data = [{
        question: '  What is an NFT?',
        answer: <div>
            <div className="mb-2">An NFT, or non-fungible token, is a one-of-a-kind digital item that gives users the ability to prove ownership of goods.
            </div>
        </div>
    },
    {
        question: ' How do I buy an NFT?',
        answer:
            <div className="mb-2">To purchase a Carbon Cowboys NFTs, first you will need a Web3 wallet like Metamask. Then you will need to fund your account the cryptocurrency Ethereum, or $ETH! Then connect your wallet in the upper right corner of this site, click "Mint Now", and select the number of Carbon Cowboys you'd like to own!</div>
    },
    {
        question: 'Can I buy Carbon Cowboys NFTs with cryptocurrency?',
        answer:
            <div className="mb-2">Yes - $ETH or Ethereum is your only payment option for purchasing a Carbon Cowboy NFT.</div>

    },
    {
        question: ' Do I need a crypto wallet to buy Carbon Cowboys NFTs?',
        answer:
            <div className="mb-2">Yes - you will need a Web3 wallet like Metamask, funded with the cryptocurrency Ethereum, or $ETH. </div>

    },
    {
        question: 'How do I access my NFT?',
        answer:
            <div className="mb-2">You may view your NFT in your crypto wallet or on Winter where you can easily view, transfer, and use the NFT straight.</div>

    },
    {

        question: ' How much do the Carbon Cowboys NFTs cost?',
        answer:
            <div className="mb-2">The NFTs cost $25 USD worth of $ETH/each.</div>

    },
    {
        question: 'What is the return policy?',
        answer:
            <div className="mb-2">All sales of Carbon Cowboys NFTs are final.</div>
    },
    {
        question: ' What is the shipping policy?',
        answer:
            <div className="mb-2">All Carbon Cowboys NFTs are delivered electronically. Once you complete your purchase of your NFTs you will receive the necessary link to download your item.</div>

    },
    {
        question: ' Isn’t blockchain bad for the environment?',
        answer:
            <div className="mb-2">Ethereum used to be Proof-of-Work meaning it used energy-intensive methods to verify transactions. Ethereum switched to Proof-of-Stake in September 2022 and now uses approximately 99.99% less energy than before. Ethereum’s total estimated annual CO2 emissions have dropped from over 11 million tons to just under 870. Polygon is a carbon negative blockchain as its estimated annual CO2 emissions are 56.22 tons and it pledged to offset the emissions with $20 million in carbon credits.</div>
    }]


    return (
        <>
            <div className=" d-flex justify-content-center align-items-center text-center faq-text faq-border " id="faq">

                <h2 className="mt-3">Frequently Asked Questions</h2>

            </div>

            <section id="faq" data-aos="flip-up" className="mt-5">

                <div>
                    <Row >
                        {data.map((item, i) => (
                            <Col lg="4" md="4" className="pr-0 pl-0 ">
                                <div className="faq-section ">
                                    <div className={`d-flex justify-content-between align-items-center ${active === i ? "question-bg" : ""}`} onClick={() => toggle(i)}>
                                        <p className={` text-question mb-0 px-5 ${active === i ? 'question-show' : 'question'}`}>
                                            {item.question}
                                        </p>
                                        {active === i ? <AiOutlineMinus color="white" size={20} /> : <AiOutlinePlus color="black" className="mx-3 pointer" size={20} />}
                                    </div>
                                    <div className={`${active === i ? 'content show px-3' : 'content'}`}>
                                        {item.answer}
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </section >
        </>
    )
}

export default FAQ

