import React from 'react'
import { Col, Row } from 'reactstrap'

const Benefits = () => {
    return (
        <section id="benefits" data-aos="flip-right">
            <h2 className='text-center'>
                Holder Benefits
            </h2>
            <Row className='align-items-center'>
                <Col lg="6" className='text-center pb-5 pb-lg-0 p-0'>
                    <div className='benefit-border1 p-4'>
                        <img src={require('../../assets/img/Cowboy_images/Benefit-img.png').default} height={"100%"} width={"100%"} className="img-fluid" />
                    </div>

                </Col>
                <Col lg="6 " >
                    <div className='benefit-border2 p-3'>
                        <div className='ben-border'>
                            <h3 className='mb-2'>Save the Environment</h3>
                            <p className='mb-2 text-dark pb-3'>Each NFT represents 1 ton of carbon reduced from the global carbon footprint. Holders can receive a digital certificate commending their commitment to saving the environment upon request.</p>
                        </div>
                        <div className='ben-border'>
                            <h3 className='mb-2 mt-2'>Ethereum Rewards</h3>
                            <p className='mb-2 text-dark pb-3'>On December 8th, 2023, 50 holders will receive Ethereum equivalent to the real-time market value of 10 tons of nature-based carbon offsets.</p>
                        </div>
                        <div className='ben-border '>
                            <h3 className='mb-2 mt-2'>Access to Second Collection</h3>
                            <p className='mb-2 text-dark pb-3'>Holders will get guaranteed access to Carbon Cowboys Gen II.</p>
                        </div>

                        <h3 className='mb-2 mt-2'>Token Airdrops</h3>
                        <p className='mb-2 text-dark'>We are in the process of developing a token. More information is coming soon.</p>
                    </div>

                </Col>

            </Row>
        </section >
    )
}

export default Benefits