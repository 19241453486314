import React from 'react'
import { Row, Col } from "reactstrap"
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <section id="melliens" className='pb-4' data-aos="zoom-in">
            <h2 className='text-center'>About Melliens</h2>

            <div className=''>
                <Row className='  '>
                    <div className='about-border d-flex flex-lg-row flex-column align-items-center justify-content-center'>

                        <Col lg="6" className=' p-3 ' >
                            <div className=''>
                                <div className='text-left mt-3 '>
                                    <img src={require('../../assets/img/Cowboy_images/tick1.png').default} height={50} width={50} className="img-fluid " />
                                </div>

                                <h4 className='mb-1 mt-3 text-left '>Melliens as a Climate Tech Company</h4>
                                <p className=''>Melliens is the first global solution company to monitor beef carbon emissions via bio big data. Farmers can reduce the greenhouse gas footprint of their herds, earn extra income, and make a positive impact on the environment by implementing a combination of sustainable farming practices and monitoring individual cattle’s GHG emissions. Our cutting-edge technology has been created through over a decade of extensive scientific research.</p>
                            </div>
                        </Col>
                        <Col lg="5" className='text-center ' data-aos="fade-up"
                            data-aos-duration="3000">
                            <a href="http://melliens.com/" target='_blank'><img src={require('../../assets/img/Cowboy_images/about1.png').default} height={"80%"} width={"80%"} className="img-fluid" /></a>
                        </Col>
                    </div>
                </Row>

                <Row className=' '>
                    <div className='about-border  d-flex flex-lg-row flex-column align-items-center justify-content-center'>
                        <Col lg="6" className='p-3' >
                            <div className='text-left mt-3'>
                                <img src={require('../../assets/img/Cowboy_images/tick2.png').default} height={50} width={50} className="img-fluid" />
                            </div>
                            <h4 className='mb-1 mt-3 text-left'>The Proprietary Carbon Credit<br /> Generation and Trading Platform</h4>
                            <p className=''>Our technology uses a system that enables immediate compensation to farmers by quantifying the reduced amount of carbon footprint in real-time motivates farmers to actively adopt sustainable practices, such as breeding, feed additives, and management. If this low-cost and effective carbon credit generation system were applied globally and systematically, carbon emissions would be dramatically reduced</p>
                        </Col>
                        <Col lg="5" className='text-center ' data-aos="fade-up"
                            data-aos-duration="3000">
                            <a href="http://cowcarbon.com/home" target='_blank'><img src={require('../../assets/img/Cowboy_images/about2.png').default} height={"80%"} width={"80%"} className="img-fluid" /></a>
                        </Col>
                    </div>
                </Row>

                <Row className=' '>
                    <div className='about-border d-flex flex-lg-row flex-column align-items-center justify-content-center'>
                        <Col lg="6" className=' p-3 '>
                            <div className='text-left mt-3'>
                                <img src={require('../../assets/img/Cowboy_images/tick3.png').default} height={50} width={50} className="img-fluid" />
                            </div>

                            <h4 className='mb-1 mt-3 text-lg-left '>Scientific Research and Expertise Behind Melliens</h4>
                            <p className=''>Melliens is based on 10 years of research and preparation. Our team consists of 2 MBAs, a Professor with a Ph.D. in Animal Breeding, a Professor with a Ph.D. in Animal Science, an Engineer with a M.S. in Mechatronics and a Ph.D. in Animal Breeding, and a Researcher with a Ph.D. in Genetic Breeding. We have worked at many leading companies like Samsung, Cisco, IBM, Dell, Blue Origin, Macrogen, and Concord Ventures.

                                We hold 2 international and 2 US patents for low-carbon product certification using slaughter traceability information and for reducing greenhouse gas using livestock genomic information.</p>

                        </Col>
                        <Col lg="5" className='text-center ' data-aos="fade-up"
                            data-aos-duration="3000">
                            <img src={require('../../assets/img/Cowboy_images/about3.png').default} height={"80%"} width={"80%"} className="img-fluid" />
                        </Col>
                    </div>
                </Row>

                <div className="text-center certificate">
                    <a href="https://carboncowboys.io/carbon_offset_certificate_melliens.pdf" target='_blank' className="">
                        <h3 className='mt-5 certificate'><u>Carbon Offset Certificate</u></h3>
                    </a>
                </div>
            </div>
        </section >
    )
}

export default About