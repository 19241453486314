/*!

=========================================================
* sikh_symbols - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

export default function Footer() {
  return (
    <section data-aos="flip-left" >

      <Row className="d-flex justify-content-between mt-5 mt-lg-0 pt-5">
        <Col md="4" lg="4" className="align-items-center justify-content-center pt-5 mt-5 mt-lg-0 pt-lg-0">
          <div className="pb-5 foot-text text-center text-lg-left">
            <img src={require('../../assets/img/Cowboy_images/foot-logo.png').default} className="img-fluid" height={"70%"} width={"70%"} />
            <p className="mt-3">
              Melliens is the first global solution<br /> company to monitor beef carbon<br /> emissions via bio big data.
            </p>

          </div>
        </Col>
        <Col md="4" lg="4" className="text-center">

        </Col>
        <Col md="4" lg="4" className="d-flex flex-column align-items-lg-end align-items-center justify-content-center foot-text  pb-3">
          <div className="d-flex align-items-center">
            <p >
              NFTs
            </p>
            <a href="/#nfts" >
              <img src={require('../../assets/img/Cowboy_images/Union.png').default} className="img-fluid ml-5 pl-3" />
            </a>
          </div>
          <div className="d-flex align-items-center ">
            <p>
              Benefits
            </p>
            <a href="/#benefits">
              <img src={require('../../assets/img/Cowboy_images/Union.png').default} className="img-fluid ml-5" />
            </a>
          </div>
          <div className="d-flex align-items-center ">
            <p>
              Melliens
            </p>
            <a href="/#melliens" >
              <img src={require('../../assets/img/Cowboy_images/Union.png').default} className="img-fluid ml-5" />
            </a>
          </div>
          <div className="d-flex align-items-center ">
            <p>
              FAQ
            </p>
            <a href="/#faq">
              <img src={require('../../assets/img/Cowboy_images/Union.png').default} className="img-fluid ml-5 pl-4" />
            </a>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-between border-footer py-4">

        <Col md="4" lg="4" className="text-center text-md-left foot-text">
          <p className="mb-0">© Melliens / Cowcarbon. All Rights Reserved.</p>
        </Col>
        <Col md="4" lg="4" className="text-center mt-3 mt-md-0">

        </Col>
        <Col md="4" lg="4" className="mt-3 mt-md-0">
          <div className="d-flex justify-content-center justify-content-md-end">
            <a href="https://twitter.com/carbon_cowboys" className="">
              <img src={require('../../assets/img/Cowboy_images/foot-twit.png').default} className="img-fluid" height={"60%"} width={"60%"} />
            </a>
            <a href="https://discord.gg/4nQABT6edG" className="">
              <img src={require('../../assets/img/Cowboy_images/foot-disc.png').default} className="img-fluid" height={"60%"} width={"60%"} />
            </a>
          </div>
        </Col>
      </Row>
    </section >
  );
}
