/*!

=========================================================
* carbon_cowboys - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
import { isMobile } from "../../helper/index";
import { useWeb3React } from '@web3-react/core';
import WalletDropdown from "components/ConnectWallet/WalletDropdown";
import connectors from "../../helper/walletConnector"
import activateInjectedProvider from "../../helper/walletHelp"
import ConnectWallet from "components/ConnectWallet";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import { FiMenu } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa'

export default function IndexNavbar() {

  const { account, active, activate } = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  useEffect(() => {
    const provider = localStorage.getItem("provider");
    if (provider) {
      if (provider === 'injected') {
        if (!isMobile() && !window.ethereum) {
          window.open('https://metamask.io/download/', '_blank');
        } else {
          activateInjectedProvider('MetaMask');
          activate(connectors.injected);
        }
      } else activate(connectors[provider]);
    }

  }, []);

  return (
    <div className=" pt-5">
      <div className="nav-border ">
        <Navbar expand="xl" className="py-0">
          <Row className="w-100">
            <Col lg="12" xl="2" className="px-0 px-xl-3">
              <div className="d-flex w-100 justify-content-between ">

                <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                  <div className="d-flex align-items-center justify-content-center  pr-3">
                    <img src={require('../../assets/img/Cowboy_images/nav-logo.png').default} height={100} width={100} className="img-fluid ml-4 " />
                  </div>
                </NavbarBrand>

                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <FiMenu className="text-dark" size="24" />
                </button>
              </div>
            </Col>
            <Col lg="8" xl="10" className="pr-0 d-flex justify-content-end">
              <Collapse
                className={"ml-4 bg-black justify-content-end justify-content-lg-between " + collapseOut}
                navbar
                isOpen={collapseOpen}
                onExiting={onCollapseExiting}
                onExited={onCollapseExited}
              >

                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="6">
                      <button
                        aria-expanded={collapseOpen}
                        className="navbar-toggler"
                        onClick={toggleCollapse}
                      >
                        <FaTimes className="pf-text-secondary" color="white" size="24" />
                      </button>

                    </Col>
                  </Row>
                </div>


                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div className="d-flex flex-column flex-xl-row w-100 justify-content-between">
                    <Nav navbar className="nav-text-size ">
                      <NavItem className="px-2 mt-2">
                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="/" className="mr-4 " >Home</a>
                        </NavLink>
                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="/#nfts" className="mr-4">NFTs</a>
                        </NavLink>
                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="/#benefits" className="mr-4" >Benefits</a>
                        </NavLink>

                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="/#melliens" className="mr-4" >Melliens</a>
                        </NavLink>
                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="/#faq" className="mr-4" >FAQ</a>
                        </NavLink>
                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="https://twitter.com/carbon_cowboys" >
                            <img src={require('../../assets/img/Cowboy_images/nav-twit.png').default} height={30} width={30} className="img-fluid ml-4" />
                          </a>
                        </NavLink>
                        <NavLink
                          data-placement="bottom"
                          rel="noopener noreferrer"
                        >
                          <a href="https://discord.gg/4nQABT6edG" >
                            <img src={require('../../assets/img/Cowboy_images/nav-disc.png').default} height={30} width={30} className="img-fluid ml-4" />
                          </a>
                        </NavLink>

                      </NavItem>

                    </Nav>
                    <div className="my-4 my-xl-0  wal-text">

                    {(!active) &&
                      <button className="py-2 px-3 my-1" onClick={() => setIsOpen(true)}>CONNECT WALLET</button>
                    }
                    {active && account &&
                      <WalletDropdown />
                    }
                    
                    </div>
                  </div>

                </div>
              </Collapse>
            </Col>
          </Row>
        </Navbar>
        <ConnectWallet setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
    </div >
  );
}
