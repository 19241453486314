import IndexNavbar from 'components/Navbars/IndexNavbar'
import React from 'react'
import { Row, Col, Input } from "reactstrap"
import Mint from "components/Mint"

const Banner = () => {
    return (
        <>
            <div>
                <section className='py-0'>
                    <IndexNavbar />
                </section>
                <section data-aos="zoom-in-up" >
                    <div className='banner'>


                        <Row className=''>
                            <Col lg="12" className='d-flex flex-column flex-lg-row align-items-center justify-content-between ban-border'>

                                <div className='d-block d-lg-none text-center mob-ban-text'>
                                    <h1 className=' mb-0'>Carbon</h1>
                                    <h1 className='ban-border '>Cowboys</h1>

                                    <img src={require('../../assets/img/Cowboy_images/banner.png').default} height={"100%"} width={"100%"} className="img-fluid mb-5 ml-lg-0 ml-4" />
                                </div>


                                <div className='text-lg-left text-center ban-text pt-4 d-lg-block d-none'>
                                    <h1 className=' mb-0'>Carbon</h1>
                                </div>

                                <div className='d-flex flex-column text-lg-left ml-lg-0 ml-4'>
                                    <p className='text-dark ban-par-text'>
                                        A collection of 3,000 NFTs to incentivize sustainable <br /> agricultural practices while reducing carbon emissions.
                                    </p>
                                    <p>
                                        We are a climate tech company that generates carbon credits by <br />monitoring the carbon footprints of beef cattle. Melliens offers a<br /> proprietary carbon credit generation and trading platform based<br /> on big data and animal biotechnology research.
                                    </p>
                                </div>

                            </Col>
                            <Col lg="12" className='h-100  mb-2 '>

                                <div className='d-flex flex-xl-row flex-column '>

                                    <img src={require('../../assets/img/Cowboy_images/banner.png').default} height={"50%"} width={"50%"} className="img-fluid mb-5 d-lg-block d-none" />


                                    <div className='text-lg-left text-center ban-text pt-lg-4 pt-0 mt-lg-5 mt-0 d-lg-block d-none'>
                                        <h1 className='ban-border '>Cowboys</h1>

                                        <Mint />
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <Row className='pt-5 mt-5'>
                        <Col xl="6" lg="12">
                            <div className='text-lg-left  text-center nft-text'>
                                <h2>NFT <br />Collection</h2>
                            </div>
                        </Col>
                        <Col xl="6" lg="12">
                            <p>
                                Each NFT represents<span className='text-dark'> 1 ton of carbon</span>  which will be retired once the NFT is purchased. <span className='text-dark'>Retired carbon credits </span>can no longer be traded and are effectively taken out of the market, reducing the  <span className='text-dark'>overall carbon footprint.</span>
                            </p>
                            <p>

                                After <span className='text-dark'> buying NFTs</span> and retiring carbon offsets, the artwork still keeps its value and can be traded like a membership. By purchasing the <span className='text-dark'>Carbon Cowboys NFTs,</span> buyers show their support for farmers and their <span className='text-dark'>sustainable agricultural practices.</span>
                            </p>
                        </Col>
                    </Row>
                </section>

            </div >
            {/* <section>
                <Row className='' id="nfts">
                    <Col lg="12">
                        <img src={require('../../assets/img/Cowboy_images/grp-img.png').default} className="img-fluid" />
                    </Col>
                </Row>

            </section> */}
        </>
    )
}

export default Banner